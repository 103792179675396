<script>
import { Carousel, Slide } from 'vue-carousel';
import { mapGetters } from 'vuex';

export default {
    components: { Carousel, Slide },
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            Press: 'home/Press',
        }),
    },
    created() {
        this.$store.dispatch('home/get_Press');
    },
};
</script>
<template>
    <b-container fluid class="p-0">
        <div class="press">
            <div class="press-custom-box">
                <h3>{{ $t('thePress') }}</h3>
                <Carousel
                    :perPageCustom="[
                        [320, 1],
                        [575, 2],
                        [991, 3],
                        [1200, 3],
                        [1600, 3],
                        [2200, 3],
                        [3200, 3],
                    ]"
                    :navigationEnabled="false"
                    :navigationNextLabel="'>'"
                    :navigationPrevLabel="'<'"
                >
                    <Slide
                        class="item-carousel"
                        v-for="press in Press"
                        :key="press.newspaper_id"
                    >
                        <a
                            :href="press.newspaper_link"
                            target="_blank"
                            class="press-item"
                        >
                            <div class="pres-img">
                                <img :src="press.newspaper_image" alt="" />
                            </div>
                            <div class="pres-content">
                                <p>
                                    {{ press.newspaper_title }}
                                </p>
                            </div>
                        </a>
                    </Slide>
                </Carousel>
            </div>
        </div>
    </b-container>
</template>

<style lang="scss">
.press {
    width: 100%;
    padding: 50px 0px 500px 0px;
    background-image: url('~@/assets/images/press/bg-press.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100%;

    .press-custom-box {
        width: 1100px;
        margin: 0 auto;
        @media (max-width: 1199px) {
            width: 950px;
        }
        @media (max-width: 767px) {
            width: 540px;
        }
        @media (max-width: 575px) {
            width: 350px;
        }
        @media (max-width: 375px) {
            width: 320px;
        }
    }
    @media (max-width: 575px) {
        padding: 30px 0px;
    }
    h3 {
        width: 100%;
        text-align: center;
        font-weight: bold;
        font-size: 40px;
        color: #ffffff;
        margin-bottom: 50px;
        @media (max-width: 575px) {
            font-size: 30px;
        }
    }
    .VueCarousel {
        .VueCarousel-navigation {
            .VueCarousel-navigation-prev {
                font-family: monospace;
                color: #0930ff;
                content: '<';
                font-size: 60px;
                top: 40% !important;
                outline: none;
                @media (max-width: 991px) {
                    font-size: 45px;
                }
                @media (max-width: 575px) {
                    left: 25px !important;
                }
            }
            .VueCarousel-navigation-next {
                font-family: monospace;
                color: #0930ff;
                content: '>';
                font-size: 60px;
                top: 40% !important;
                outline: none;
                @media (max-width: 991px) {
                    font-size: 45px;
                }
                @media (max-width: 575px) {
                    right: 25px !important;
                }
            }
        }
        .VueCarousel-wrapper {
            .VueCarousel-inner {
                @media (max-width: 575px) {
                }
                .item-carousel {
                    padding: 20px;
                    @media (max-width: 767px) {
                        padding: 10px;
                    }
                    @media (max-width: 575px) {
                        padding: 10px 30px;
                    }
                    .press-item {
                        width: 100%;
                        height: 100%;
                        max-width: 300px;
                        min-height: 350px;
                        color: #ffffff;
                        position: relative;
                        overflow: hidden;
                        z-index: 1;
                        margin: 0 auto;
                        border-top-left-radius: 20px;
                        border-top-right-radius: 20px;
                        background-color: #ffffff;
                        .pres-img {
                            width: 100%;
                            height: 250px;
                            background-color: #ffffff;
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: scale-down;
                                border-top-left-radius: 20px;
                                border-top-right-radius: 20px;
                            }
                        }
                        .pres-content {
                            padding: 20px;
                            background-color: #fff;
                            background-image: linear-gradient(
                                to top,
                                #003395 50%,
                                #008cffab
                            );
                            p {
                                text-align: center;
                                display: none;
                                margin-bottom: 5px;
                                font-size: 17px;
                                position: relative;
                                z-index: 2;
                                display: -webkit-box;
                                line-height: 25px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                                font-weight: bold;
                                @media (max-width: 1199px) {
                                    font-size: 15px;
                                }
                            }
                        }
                        .line-bottom {
                            width: 100%;
                            height: 20px;
                            background-color: #ffffff;
                            position: absolute;
                            z-index: 0;
                            left: 0;
                            bottom: 0;
                        }
                        .box-sub-bg {
                            position: absolute;
                            bottom: 0px;
                            left: 0px;
                            width: 100%;
                            height: 100%;
                            max-height: 120px;
                            background-image: linear-gradient(
                                to bottom,
                                #003189,
                                #000000
                            );
                            opacity: 0.9;
                            box-shadow: -5px -30px 30px #003089;
                            display: flex;
                            align-items: flex-end;
                        }
                    }
                }
            }
        }
        .VueCarousel-pagination {
            .VueCarousel-dot-container {
                margin-top: 0px !important;
                .VueCarousel-dot {
                    background-color: #999 !important;
                    opacity: 1;
                    width: 15px !important;
                    height: 15px !important;
                    outline: none;
                    @media (max-width: 991px) {
                        width: 15px !important;
                        height: 15px !important;
                    }
                    @media (max-width: 575px) {
                        width: 10px !important;
                        height: 10px !important;
                    }
                    &.VueCarousel-dot--active {
                        background-color: #003189 !important;
                    }
                }
            }
        }
    }
}
</style>
