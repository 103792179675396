<script>
export default {
    data() {
        return {
            contentActive: null,
            timeOut: null,
            windowWidth: 1000,
        };
    },
    methods: {
        activeBox(tab) {
            this.contentActive = tab;
        },
        activeSome() {
            if (this.contentActive == null || this.contentActive > 6) {
                this.contentActive = 1;
            }
            this.timeOut = setTimeout(() => {
                this.contentActive += 1;
                this.activeSome();
            }, 2000);
        },
        onWindowResize() {
            this.windowWidth = window.innerWidth;
            if (this.windowWidth <= 575) {
                clearTimeout(this.timeOut);
                this.activeSome();
            } else if (this.timeOut != null) {
                clearTimeout(this.timeOut);
            }
        },
    },
    mounted() {
        this.onWindowResize();
    },
    created() {
        window.addEventListener('resize', this.onWindowResize);
    },
    destroyed() {
        window.removeEventListener('resize', this.onWindowResize);
    },
};
</script>
<template>
    <b-container fluid class="why-box">
        <div class="why-box-title" data-aos="fade-down">{{ $t('connectWithUs') }}</div>
        <div class="why-box-content">
            <div class="bg-why">
                <img
                    src="~@/assets/images/mock/why/big.png"
                    alt=""
                    class="big-circle"
                />
                <img
                    src="~@/assets/images/mock/why/small.png"
                    alt=""
                    class="small-circle"
                />
                <img
                    src="~@/assets/images/mock/why/logo.png"
                    alt=""
                    class="logo"
                />
                <div class="logo-circle">
                    <img
                        src="~@/assets/images/loader/circle.png"
                        alt=""
                        class="circle-bg"
                    />
                    <img
                        src="~@/assets/images/loader/logo.png"
                        alt=""
                        class="logo-b"
                    />
                </div>
            </div>
            <div class="content-why">
                <div
                    class="box box-1"
                    @click.prevent="activeBox(1)"
                    :class="{ active: contentActive === 1 }"
                >
                    <img
                        src="~@/assets/images/mock/why/1-4.png"
                        alt=""
                        class="bg"
                    />
                    <img
                        src="~@/assets/images/mock/why/1.png"
                        alt=""
                        class="icons"
                    />
                    <div class="text">
                        {{ $t('teamOfExperts') }}
                    </div>
                </div>
                <div
                    class="box box-2"
                    @click="activeBox(2)"
                    :class="{ active: contentActive === 2 }"
                >
                    <img
                        src="~@/assets/images/mock/why/1-4.png"
                        alt=""
                        class="bg"
                    />
                    <img
                        src="~@/assets/images/mock/why/2.png"
                        alt=""
                        class="icons"
                    />
                    <div class="text">
                        {{ $t('professional') }}
                    </div>
                </div>
                <div
                    class="box box-3"
                    @click="activeBox(3)"
                    :class="{ active: contentActive === 3 }"
                >
                    <img
                        src="~@/assets/images/mock/why/1-4.png"
                        alt=""
                        class="bg"
                    />
                    <img
                        src="~@/assets/images/mock/why/3.png"
                        alt=""
                        class="icons"
                    />
                    <div class="text">{{ $t('knowledgeTransfer') }}</div>
                </div>
                <div
                    class="box box-4"
                    @click="activeBox(4)"
                    :class="{ active: contentActive === 4 }"
                >
                    <img
                        src="~@/assets/images/mock/why/1-4.png"
                        alt=""
                        class="bg"
                    />
                    <img
                        src="~@/assets/images/mock/why/4.png"
                        alt=""
                        class="icons"
                    />
                    <div class="text">
                        {{ $t('managementAbility') }}
                    </div>
                </div>
                <div
                    class="box box-5"
                    @click="activeBox(5)"
                    :class="{ active: contentActive === 5 }"
                >
                    <img
                        src="~@/assets/images/mock/why/1-4.png"
                        alt=""
                        class="bg"
                    />
                    <img
                        src="~@/assets/images/mock/why/5.png"
                        alt=""
                        class="icons"
                    />
                    <div class="text">
                        {{ $t('modernTechnology') }}
                    </div>
                </div>
                <div
                    class="box box-6"
                    @click="activeBox(6)"
                    :class="{ active: contentActive === 6 }"
                >
                    <img
                        src="~@/assets/images/mock/why/1-4.png"
                        alt=""
                        class="bg"
                    />
                    <img
                        src="~@/assets/images/mock/why/6.png"
                        alt=""
                        class="icons"
                    />
                    <div class="text">
                        {{ $t('professionalDedicated') }}
                    </div>
                </div>
            </div>
        </div>
    </b-container>
</template>

<style lang="scss">
.why-box {
    padding: 50px 0;
    background: {
        image: url(~@/assets/images/background/why.png);
        size: cover;
        position: center center;
    }
    .why-box-title {
        color: #003189;
        font-family: 'Roboto', sans-serif;
        font-size: 40px;
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;
        @media (max-width: 991px) {
            font-size: 30px;
            margin-bottom: 30px;
        }
    }
    .why-box-content {
        position: relative;
        width: 768px;
        height: 768px;
        margin: auto;
        .bg-why {
            img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                z-index: 0;
            }
            .big-circle {
                width: 600px;
                height: 600px;
            }
            .small-circle {
                width: 300px;
                height: 300px;
            }
            .logo {
                width: 150px;
                height: auto;
            }
            .logo-circle {
                display: none;
            }
        }
        .content-why {
            position: relative;
            width: 100%;
            height: 100%;
            animation: rote 20s infinite linear;
            &:hover {
                animation-play-state: paused;
                transition: all 0.5s;
                .box {
                    animation-play-state: paused;
                }
            }
            .box {
                position: absolute;
                width: 190px;
                height: 170px;
                animation: rote2 20s infinite linear;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                z-index: 20;
                .bg {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                }
                .icons {
                    width: 40px;
                    position: relative;
                    z-index: 2;
                    margin: 5px auto;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
                .text {
                    position: relative;
                    z-index: 2;
                    font-size: 15px;
                    text-align: center;
                    color: #000000;
                    font-weight: 600;
                    line-height: 110%;
                    padding: 0 15px 20px;
                    width: 170px;
                }
            }
            .box-1 {
                top: 10px;
                left: 0;
                right: 0;
                margin: auto;
            }
            .box-2 {
                right: 65px;
                top: 20%;
                margin: auto;
                transform: rotate(0deg);
                .text,
                .icons {
                    transform: rotate(0deg);
                }
            }
            .box-3 {
                right: 75px;
                bottom: 0;
                top: 40%;
                margin: auto;
                transform: rotate(2deg);
                .text,
                .icons {
                    transform: rotate(-2deg);
                }
            }
            .box-4 {
                bottom: 25px;
                left: 0;
                right: 0;
                margin: auto;
            }
            .box-5 {
                left: 55px;
                top: 20%;
                margin: auto;
                transform: rotate(-1deg);
                .text,
                .icons {
                    transform: rotate(1deg);
                }
            }
            .box-6 {
                left: 65px;
                bottom: 0;
                top: 40%;
                margin: auto;
                transform: rotate(-1deg);
                .text,
                .icons {
                    transform: rotate(1deg);
                }
            }
        }

        @media (max-width: 991px) {
            width: 500px;
            height: 500px;
            .bg-why {
                .big-circle {
                    width: 400px;
                    height: 400px;
                }
                .small-circle {
                    width: 200px;
                    height: 200px;
                }
                .logo {
                    width: 75px;
                    height: auto;
                }
            }
            .content-why {
                .box {
                    width: 150px;
                    height: 130px;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    flex-direction: column;
                    .icons {
                        width: 25px;
                        position: relative;
                        z-index: 2;
                        margin: 10px auto 5px;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                    .text {
                        position: relative;
                        z-index: 2;
                        font-size: 12px;
                        text-align: center;
                        color: #000000;
                        font-weight: 600;
                        line-height: 110%;
                        padding: 0 27px 20px;
                        width: 170px;
                    }
                }
                .box-1 {
                    top: 5px;
                }
                .box-2 {
                    right: 15px;
                    top: 20%;
                    margin: auto;
                    transform: rotate(4deg);
                    .text,
                    .icons {
                        transform: rotate(0deg);
                    }
                }
                .box-3 {
                    right: 15px;
                    bottom: 0;
                    top: 35%;
                    margin: auto;
                    transform: rotate(2deg);
                    .text,
                    .icons {
                        transform: rotate(-2deg);
                    }
                }
                .box-4 {
                    bottom: 10px;
                    left: 0;
                    right: 0;
                    margin: auto;
                }
                .box-5 {
                    left: 15px;
                    top: 20%;
                    margin: auto;
                    transform: rotate(-1deg);
                    .text,
                    .icons {
                        transform: rotate(1deg);
                    }
                }
                .box-6 {
                    left: 20px;
                    bottom: 0;
                    top: 35%;
                    margin: auto;
                    transform: rotate(-1deg);
                    .text,
                    .icons {
                        transform: rotate(1deg);
                    }
                }
            }
        }
        @media (max-width: 575px) {
            width: 100%;
            height: auto;

            .bg-why {
                position: relative;
                height: 300px;
                display: flex;
                justify-content: center;
                align-items: center;
                .big-circle {
                    width: 250px;
                    height: 250px;
                    animation: changeWith 3s infinite;
                    transition: all 0.5s;
                }
                .small-circle {
                    width: 200px;
                    height: 200px;
                    animation: changeWith2 3s infinite;
                    transition: all 0.5s;
                }
                .logo {
                    width: 150px;
                    height: auto;
                    display: none;
                }
                .logo-circle {
                    width: 175px;
                    height: 175px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .circle-bg {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        animation: circleRotate 4s linear infinite;
                        z-index: 0;
                    }
                    .logo-b {
                        height: 80%;
                        width: auto;
                        position: relative;
                        animation: logoLight 4s linear infinite;
                    }
                }
            }
            .content-why {
                animation: none;
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;
                .box {
                    width: 150px;
                    height: 130px;
                    position: relative;
                    margin: 15px 10px;
                    animation: none;
                    transform: rotate(0deg) !important;
                    top: 0;
                    left: 0;
                    right: 0;
                    &.active {
                        transform: scale(1.1) rotate(0deg) !important;
                        z-index: 25;
                    }
                    .icons {
                        width: 25px;
                        position: relative;
                        z-index: 2;
                        margin: 10px auto 5px;
                        transform: rotate(0deg) !important;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                    .text {
                        position: relative;
                        z-index: 2;
                        font-size: 12px;
                        text-align: center;
                        color: #000000;
                        font-weight: 600;
                        line-height: 110%;
                        padding: 0 27px 20px;
                        transform: rotate(0deg) !important;
                        width: 170px;
                    }
                }
            }
        }
        @media (max-width: 374px) {
            .content-why {
                .box {
                    margin: 15px 5px;
                    .text {
                        width: 160px;
                    }
                }
            }
        }
    }
}
@keyframes rote {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes rote2 {
    0% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
@keyframes circleRotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes logoLight {
    0% {
        filter: drop-shadow(0px 0px 0px #000);
        z-index: 0;
    }
    50% {
        filter: drop-shadow(0px 0px 2px #000);
        z-index: 1;
    }
    100% {
        filter: drop-shadow(0px 0px 0px #000);
        z-index: 0;
    }
}
@keyframes changeWith {
    0% {
        width: 205px;
        height: 205px;
        opacity: 1;
    }
    50% {
        width: 305px;
        height: 305px;
        opacity: 0.2;
    }
    100% {
        width: 205px;
        height: 205px;
        opacity: 1;
    }
}
@keyframes changeWith2 {
    0% {
        width: 204px;
        height: 204px;
        opacity: 1;
    }
    50% {
        width: 105px;
        height: 105px;
        opacity: 0.2;
    }
    100% {
        width: 204px;
        height: 204px;
        opacity: 1;
    }
}
</style>
