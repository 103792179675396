<script>
import { Carousel, Slide } from 'vue-carousel';
import { mapGetters } from 'vuex';

export default {
    components: { Carousel, Slide },
    data() {
        return {
        };
    },
    computed: {
        ...mapGetters({
            MostNews: 'news/MostNews',
        }),
    },
    created() {
        this.$store.dispatch('news/get_MostNews');
    },
};
</script>
<template>
    <b-container>
        <div class="news">
            <h3 class="text-uppercase">{{ $t('news') }}</h3>
            <Carousel
                :perPageCustom="[
                    [320, 1],
                    [575, 2],
                    [991, 3],
                    [1200, 3],
                    [1600, 3],
                    [2200, 3],
                    [3200, 3],
                ]"
                :navigationEnabled="true"
                :navigationNextLabel="'>'"
                :navigationPrevLabel="'<'"
            >
                <Slide
                    class="item-carousel"
                    v-for="news in MostNews"
                    :key="news.article_id"
                >
                    <div class="news-item">
                        <div class="new-img">
                            <img :src="news.article_thumbnail" alt="" />
                        </div>
                        <div class="new-content">
                            <h4>{{ news.article_title }}</h4>
                            <p>
                                {{ news.article_description }}
                            </p>
                            <router-link
                                :to="{
                                    name: 'NewsDetail',
                                    params: {
                                        slug: news.article_slug,
                                        type: 'tin-tuc',
                                    },
                                }"
                            >
                                {{ $t('seeMore') }}
                            </router-link>
                        </div>
                        <div class="line-bottom"></div>
                        <div class="box-sub-bg"></div>
                    </div>
                </Slide>
            </Carousel>
        </div>
    </b-container>
</template>

<style lang="scss">
.news {
    width: 100%;
    padding: 50px 0px;
    @media (max-width: 575px) {
        padding: 30px 0px;
    }
    h3 {
        width: 100%;
        text-align: center;
        font-weight: bold;
        font-size: 40px;
        @media (max-width: 575px) {
            font-size: 30px;
        }
    }
    .VueCarousel {
        .VueCarousel-navigation {
            @media (max-width: 375px) {
                display: none !important;
            }
            .VueCarousel-navigation-prev {
                font-family: monospace;
                color: #0930ff;
                content: '<';
                font-size: 60px;
                top: 40% !important;
                outline: none;
                @media (max-width: 991px) {
                    font-size: 45px;
                }
                @media (max-width: 575px) {
                    left: 25px !important;
                }
            }
            .VueCarousel-navigation-next {
                font-family: monospace;
                color: #0930ff;
                content: '>';
                font-size: 60px;
                top: 40% !important;
                outline: none;
                @media (max-width: 991px) {
                    font-size: 45px;
                }
                @media (max-width: 575px) {
                    right: 25px !important;
                }
            }
        }
        .VueCarousel-wrapper {
            .VueCarousel-inner {
                @media (max-width: 575px) {
                }
                .item-carousel {
                    padding: 20px;
                    @media (max-width: 767px) {
                        padding: 10px;
                    }
                    @media (max-width: 575px) {
                        padding: 10px 30px;
                    }
                    .news-item {
                        width: 100%;
                        height: 100%;
                        min-height: 350px;
                        color: #ffffff;
                        position: relative;
                        overflow: hidden;
                        box-shadow: 2px 2px 10px 5px rgba(204, 204, 204, 0.466);
                        z-index: 1;
                        .new-img {
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                        .new-content {
                            position: absolute;
                            bottom: 0;
                            padding: 10px;
                            h4 {
                                margin-bottom: 5px;
                                font-size: 23px;
                                position: relative;
                                line-height: 36px;
                                z-index: 2;
                                display: -webkit-box;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;
                                @media (max-width: 1199px) {
                                    font-size: 19px;
                                }
                            }
                            p {
                                display: none;
                                margin-bottom: 5px;
                                font-size: 17px;
                                position: relative;
                                z-index: 2;
                                display: -webkit-box;
                                line-height: 25px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                                font-weight: 400;
                                @media (max-width: 1199px) {
                                    font-size: 15px;
                                }
                            }
                            a {
                                float: right;
                                color: #ffffff;
                                font-style: italic;
                                text-decoration: underline;
                                font-size: 18px;
                                font-weight: bold;
                                position: relative;
                                z-index: 2;
                                @media (max-width: 1199px) {
                                    font-size: 16px;
                                }
                            }
                        }
                        .line-bottom {
                            width: 100%;
                            height: 20px;
                            background-color: #ffffff;
                            position: absolute;
                            z-index: 0;
                            left: 0;
                            bottom: 0;
                        }
                        .box-sub-bg {
                            position: absolute;
                            bottom: 0px;
                            left: 0px;
                            width: 100%;
                            height: 100%;
                            max-height: 120px;
                            background-image: linear-gradient(
                                to bottom,
                                #003189,
                                #000000
                            );
                            opacity: 0.9;
                            box-shadow: -5px -30px 30px #003089;
                            display: flex;
                            align-items: flex-end;
                        }
                    }
                }
            }
        }
        .VueCarousel-pagination {
            .VueCarousel-dot-container {
                @media (max-width: 575px) {
                    margin-top: 0px !important;
                }
                @media (max-width: 375px) {
                }
                .VueCarousel-dot {
                    background-color: #999 !important;
                    opacity: 1;
                    width: 15px !important;
                    height: 15px !important;
                    @media (max-width: 991px) {
                        width: 15px !important;
                        height: 15px !important;
                    }
                    @media (max-width: 575px) {
                        width: 10px !important;
                        height: 10px !important;
                    }
                    &.VueCarousel-dot--active {
                        background-color: #003189 !important;
                    }
                }
            }
        }
    }
}
</style>
