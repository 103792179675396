<script>
import { Carousel, Slide } from 'vue-carousel';
import { mapGetters } from 'vuex';

export default {
    components: { Carousel, Slide },
    computed: {
        ...mapGetters({
            Customer: 'home/Customer',
        }),
    },
    created() {
        this.$store.dispatch('home/get_Customer');
    },
};
</script>
<template>
    <b-container>
        <div class="customer-box">
            <h3>{{ $t('customers') }}</h3>
            <div class="customer-carousel">
                <Carousel
                    :perPageCustom="[
                        [320, 1],
                        [575, 2],
                        [991, 3],
                        [1200, 3],
                        [1600, 3],
                        [2200, 3],
                        [3200, 3],
                    ]"
                    :navigationEnabled="false"
                    :navigationNextLabel="'>'"
                    :navigationPrevLabel="'<'"
                >
                    <Slide
                        class="item-carousel"
                        v-for="customer in Customer"
                        :key="customer.customer_id"
                    >
                        <div class="customer-item">
                            <div class="customer-img">
                                <img :src="customer.customer_image" alt="" />
                            </div>
                            <h4>{{ customer.customer_name }}</h4>
                            <h5>{{ customer.customer_job }}</h5>
                            <p>
                                {{ customer.customer_feelings }}
                            </p>
                        </div>
                    </Slide>
                </Carousel>
            </div>
        </div>
    </b-container>
</template>

<style lang="scss">
.customer-box {
    width: 100%;
    margin-top: -450px;
    margin-bottom: 20px;
    @media (max-width: 575px) {
        margin-top: 0px;
        max-width: 460px;
        margin: 20px auto;
    }
    @media (max-width: 480px) {
        max-width: 350px;
    }
    @media (max-width: 375px) {
        max-width: 320px;
    }
    h3 {
        width: 100%;
        text-align: center;
        font-weight: bold;
        font-size: 40px;
        color: #ffffff;
        margin-bottom: 50px;
        @media (max-width: 575px) {
            font-size: 30px;
            color: #000000;
            margin-bottom: 30px;
        }
    }
    .customer-carousel {
        background-color: #ffffff;
        border-radius: 40px;
        box-shadow: 0px 0px 5px #00000076;
        padding: 50px 0px;
        .VueCarousel {
            .VueCarousel-navigation {
                .VueCarousel-navigation-prev {
                    font-family: monospace;
                    color: #0930ff;
                    content: '<';
                    font-size: 60px;
                    top: 40% !important;
                    outline: none;
                    @media (max-width: 991px) {
                        font-size: 45px;
                    }
                    @media (max-width: 575px) {
                        left: 25px !important;
                    }
                }
                .VueCarousel-navigation-next {
                    font-family: monospace;
                    color: #0930ff;
                    content: '>';
                    font-size: 60px;
                    top: 40% !important;
                    outline: none;
                    @media (max-width: 991px) {
                        font-size: 45px;
                    }
                    @media (max-width: 575px) {
                        right: 25px !important;
                    }
                }
            }
            .VueCarousel-wrapper {
                .VueCarousel-inner {
                    @media (max-width: 575px) {
                    }
                    .item-carousel {
                        padding: 20px;
                        @media (max-width: 767px) {
                            padding: 10px;
                        }
                        @media (max-width: 575px) {
                            padding: 10px 30px;
                        }
                        @media (max-width: 375px) {
                            padding: 10px;
                        }
                        .customer-item {
                            width: 100%;
                            height: 100%;
                            max-width: 300px;
                            min-height: 350px;
                            color: #ffffff;
                            position: relative;
                            overflow: hidden;
                            z-index: 1;
                            margin: 0 auto;
                            .customer-img {
                                width: 100%;
                                height: 250px;
                                display: flex;
                                justify-content: center;
                                margin-bottom: 10px;
                                @media (max-width: 767px) {
                                    height: 200px;
                                }
                                img {
                                    width: auto;
                                    height: 100%;
                                    border: 3px solid #003189;
                                    border-radius: 50%;
                                }
                            }
                            h4 {
                                color: #003189;
                                font-weight: 800;
                                width: 100%;
                                text-align: center;
                                font-family: 'Roboto', sans-serif !important;
                                margin-bottom: 7px;
                                @media (max-width: 767px) {
                                    font-size: 20px;
                                }
                            }
                            h5 {
                                font-style: italic;
                                color: #000000;
                                font-weight: 800;
                                width: 100%;
                                text-align: center;
                                font-family: 'Roboto', sans-serif !important;
                                margin-bottom: 5px;
                                @media (max-width: 767px) {
                                    font-size: 17px;
                                }
                            }
                            p {
                                font-weight: 500;
                                color: #000000;
                                width: 100%;
                                text-align: center;
                                font-family: 'Montserrat', sans-serif !important;
                                font-size: 18px;
                                display: -webkit-box;
                                line-height: 25px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                -webkit-line-clamp: 7;
                                -webkit-box-orient: vertical;
                                @media (max-width: 767px) {
                                    font-size: 15px;
                                    -webkit-line-clamp: 5;
                                }
                            }
                        }
                    }
                }
            }
            .VueCarousel-pagination {
                .VueCarousel-dot-container {
                    margin-top: 0px !important;
                    .VueCarousel-dot {
                        background-color: #999 !important;
                        opacity: 1;
                        width: 15px !important;
                        height: 15px !important;
                        outline: none;
                        @media (max-width: 991px) {
                            width: 15px !important;
                            height: 15px !important;
                        }
                        @media (max-width: 575px) {
                            width: 10px !important;
                            height: 10px !important;
                        }
                        &.VueCarousel-dot--active {
                            background-color: #003189 !important;
                        }
                    }
                }
            }
        }
    }
}
</style>
