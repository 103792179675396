var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"p-0",attrs:{"fluid":""}},[_c('div',{staticClass:"press"},[_c('div',{staticClass:"press-custom-box"},[_c('h3',[_vm._v(_vm._s(_vm.$t('thePress')))]),_c('Carousel',{attrs:{"perPageCustom":[
                    [320, 1],
                    [575, 2],
                    [991, 3],
                    [1200, 3],
                    [1600, 3],
                    [2200, 3],
                    [3200, 3] ],"navigationEnabled":false,"navigationNextLabel":'>',"navigationPrevLabel":'<'}},_vm._l((_vm.Press),function(press){return _c('Slide',{key:press.newspaper_id,staticClass:"item-carousel"},[_c('a',{staticClass:"press-item",attrs:{"href":press.newspaper_link,"target":"_blank"}},[_c('div',{staticClass:"pres-img"},[_c('img',{attrs:{"src":press.newspaper_image,"alt":""}})]),_c('div',{staticClass:"pres-content"},[_c('p',[_vm._v(" "+_vm._s(press.newspaper_title)+" ")])])])])}),1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }