<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            tabActive: 'calling',
            page: 1,
            listPage: 3,
        };
    },
    computed: {
        ...mapGetters({
            ListProject: 'project/ListProject',
        }),
    },
    watch: {
        tabActive: {
            handler(newVal) {
                if (newVal) {
                    this.$store.dispatch('project/get_listProject', {
                        type: newVal,
                    });
                }
            },
        },
        page: {
            handler(newVal) {
                if (newVal) {
                    this.$store.dispatch('project/get_listProject', {
                        type: 'calling',
                        project_service: this.tabActiveProps,
                        page: newVal,
                    });
                }
            },
        },
    },
    created() {
        this.$store.dispatch('project/get_listProject', {
            type: 'calling',
        });
    },
};
</script>
<template>
    <b-container>
        <div class="category">
            <h3 data-aos="fade-up" class="text-uppercase">{{ $t('category') }}</h3>
            <div class="tab-heading">
                <div
                    class="tab-item"
                    :class="tabActive == 'calling' ? 'tab-active' : ''"
                    @click="tabActive = 'calling'"
                >
                    {{ $t('investing') }}
                </div>
                <div
                    class="tab-item"
                    :class="tabActive == 'called' ? 'tab-active' : ''"
                    @click="tabActive = 'called'"
                >
                {{ $t('invested') }}
                </div>
            </div>
            <b-row
                class="category-content"
                v-if="
                    ListProject &&
                    ListProject.list &&
                    ListProject.list.data &&
                    ListProject.list.data.length > 0
                "
            >
                <b-col
                    cols="12"
                    sm="6"
                    md="6"
                    lg="4"
                    v-for="project in ListProject.list.data"
                    :key="`project-${project.project_id}`"
                >
                    <router-link
                        :to="{
                            name: 'ProjectDetail',
                            params: { id: project.project_slug },
                        }"
                    >
                        <div class="project-item" data-aos="flip-up">
                            <div class="project-img">
                                <img
                                    :src="
                                        ListProject.base_url +
                                        project.project_avata
                                    "
                                    alt=""
                                />
                                <div class="content-project">
                                    <h3>{{ project.project_name }}</h3>
                                    <p
                                        class="mb-0"
                                        v-html="project.project_introduce"
                                    ></p>
                                </div>
                            </div>
                            <div class="project-name">
                                <p>{{ project.project_name }}</p>
                            </div>
                            <div class="project-line"></div>
                        </div>
                    </router-link>
                </b-col>
                <b-col cols="12">
                    <div class="pagination">
                        <div
                            v-for="indexPage in ListProject.list.last_page"
                            :key="indexPage"
                            class="dot"
                            :class="page == indexPage ? 'dot-active' : ''"
                            @click="page = indexPage"
                        ></div>
                    </div>
                </b-col>
            </b-row>
            <b-row class="category-content" v-else>
                <b-col cols="12">{{ $t('temporarilyNoProjects') }}</b-col>
            </b-row>
        </div>
    </b-container>
</template>

<style lang="scss" scoped>
.category {
    width: 100%;
    margin: auto;
    padding: 50px 0px;
    text-align: center;
    @media (max-width: 575px) {
        padding: 30px 0px;
    }
    h3 {
        width: 100%;
        text-align: center;
        font-weight: bold;
        font-size: 40px;
        @media (max-width: 575px) {
            font-size: 30px;
        }
    }
    .tab-heading {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 30px;
        font-size: 20px;
        overflow-x: auto;
        // @media (max-width: 991px) {
        //     justify-content: inherit;
        // }
        .tab-item {
            width: 250px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #363795;
            font-weight: bold;
            box-shadow: 0px 0px 5px #0000005a;
            @media (max-width: 575px) {
                width: 150px;
                height: 40px;
                font-size: 16px;
            }
            &.tab-active {
                background-image: linear-gradient(to right, #363795, #0040ff);
                color: #ffffff;
            }
            cursor: pointer;
            &:nth-child(1) {
                border-top-left-radius: 20px;
            }
            &:nth-child(2) {
                border-top-right-radius: 20px;
            }
        }
    }
    .category-content {
        padding: 20px 50px;
        box-shadow: 1px 1px 5px #0000005a;
        border-radius: 10px;
        @media (max-width: 991px) {
            padding: 20px;
        }
        @media (max-width: 575px) {
            padding: 20px 0px;
        }
        .project-item {
            border-radius: 10px;
            box-shadow: 5px 5px 10px #00000058;
            margin: 20px auto;
            width: 100%;
            max-width: 250px;
            // @media (max-width: 575px) {
            //     max-width: fit-content;
            // }
            .project-img {
                position: relative;
                cursor: pointer;
                width: 100%;
                height: 200px;
                &:hover {
                    .content-project {
                        opacity: 1;
                        transition: all 1s;
                    }
                }
                img {
                    position: absolute;
                    border-radius: 10px;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
                .content-project {
                    opacity: 0;
                    display: flex;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background-color: #00000076;
                    overflow: hidden;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 5px;
                    border-radius: 10px;
                    h3 {
                        color: #ffff;
                        font-size: 16px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    p {
                        color: #ffff;
                        font-size: 14px;
                        text-align: center;
                        display: -webkit-box;
                        line-height: 20px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        margin-bottom: 0px;
                    }
                }
            }
            .project-name {
                width: 100%;
                height: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;
                p {
                    color: #000000;
                    text-align: center;
                    display: -webkit-box;
                    line-height: 30px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    font-weight: bold;
                    font-size: 20px;
                    margin-bottom: 0px;
                    @media (max-width: 575px) {
                        font-size: 16px;
                        line-height: 25px;
                    }
                }
            }
            .project-line {
                width: 100%;
                height: 8px;
                background-color: #363795;
                border-radius: 10px;
            }
        }
    }
    .pagination {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 30px;
        @media (max-width: 575px) {
            margin-top: 20px;
        }
        .dot {
            cursor: pointer;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background-color: #999999;
            margin: 0px 10px;
            &.dot-active {
                background-color: #363795;
            }
        }
    }
}
</style>
