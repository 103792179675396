<script>
import {
    Category,
    CustomerTalk,
    Help,
    News,
    Why,
    Press,
    Banner,
    AboutUs,
} from '@/components/Home';

export default {
    components: {
        Category,
        CustomerTalk,
        Help,
        News,
        Why,
        Press,
        Banner,
        AboutUs,
    },
};
</script>
<template>
    <b-container fluid class="p-0">
        <Banner />
        <Category />
        <AboutUs />
        <Why />
        <News />
        <Press />
        <CustomerTalk />
        <Help />
    </b-container>
</template>
<style></style>
