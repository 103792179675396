var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('div',{staticClass:"news"},[_c('h3',{staticClass:"text-uppercase"},[_vm._v(_vm._s(_vm.$t('news')))]),_c('Carousel',{attrs:{"perPageCustom":[
                [320, 1],
                [575, 2],
                [991, 3],
                [1200, 3],
                [1600, 3],
                [2200, 3],
                [3200, 3] ],"navigationEnabled":true,"navigationNextLabel":'>',"navigationPrevLabel":'<'}},_vm._l((_vm.MostNews),function(news){return _c('Slide',{key:news.article_id,staticClass:"item-carousel"},[_c('div',{staticClass:"news-item"},[_c('div',{staticClass:"new-img"},[_c('img',{attrs:{"src":news.article_thumbnail,"alt":""}})]),_c('div',{staticClass:"new-content"},[_c('h4',[_vm._v(_vm._s(news.article_title))]),_c('p',[_vm._v(" "+_vm._s(news.article_description)+" ")]),_c('router-link',{attrs:{"to":{
                                name: 'NewsDetail',
                                params: {
                                    slug: news.article_slug,
                                    type: 'tin-tuc',
                                },
                            }}},[_vm._v(" "+_vm._s(_vm.$t('seeMore'))+" ")])],1),_c('div',{staticClass:"line-bottom"}),_c('div',{staticClass:"box-sub-bg"})])])}),1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }