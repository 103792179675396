<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            tab: null,
        };
    },
    computed: {
        ...mapGetters({
            Question: 'home/Question',
        }),
    },
    methods: {
        activeTabs(tabID) {
            if (this.tab === tabID) {
                this.tab = null;
                return;
            }
            this.tab = tabID;
        },
    },
    created() {
        this.$store.dispatch('home/get_Question');
    },
};
</script>
<template>
    <b-container fluid class="question-section">
        <b-container>
            <div class="title">{{ $t('frequentlyAskedQuestions') }}</div>
            <div class="question-content">
                <div
                    class="question-item"
                    v-for="question in Question"
                    :key="`question-${question.question_id}`"
                >
                    <div
                        class="question-header"
                        @click="activeTabs(question.question_id)"
                    >
                        <div class="text">
                            {{ question.question }}
                        </div>
                        <div
                            class="icon"
                            :class="{ active: question.question_id == tab }"
                        >
                            <img
                                src="~@/assets/images/icons/arrow.png"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="question-container-2">
                        <transition
                            name="custom-classes-transition"
                            enter-active-class="animate__animated animate__fadeInDown"
                            leave-active-class="animate__animated animate__fadeOutUp"
                        >
                            <div
                                class="question-body"
                                v-if="question.question_id == tab"
                            >
                                {{ question.answer }}
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </b-container>
    </b-container>
</template>

<style lang="scss">
.question-section {
    .title {
        font-family: 'Roboto', sans-serif;
        font-size: 40px;
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 30px;
        @media (max-width: 991px) {
            font-size: 30px;
        }
    }
    background-color: #f7f7f7;
    padding: 50px 0;
    height: auto;
    & * {
        transition: all 0.5s;
    }
    .question-content {
        margin: auto;
        width: 100%;
        max-width: 768px;
        .question-item {
            padding: 10px;
            overflow: hidden;
            .question-header {
                cursor: pointer;
                height: 50px;
                width: 100%;
                background: #fff;
                border-radius: 25px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0px 15px 0px 5%;
                box-shadow: 3px 6px 5px rgba($color: #000000, $alpha: 0.25);
                position: relative;
                z-index: 10;
                @media (max-width: 768px) {
                    height: 70px;
                }
                .text {
                    font-weight: 600;
                    font-family: 'Roboto', sans-serif;
                    font-size: 18px;
                    flex: 1 1 auto;
                    text-align: left;
                    display: -webkit-box;
                    line-height: 25px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                .icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex: 0 0 35px;
                    img {
                        height: 35px;
                        width: 35px;
                    }
                    &.active {
                        transform: rotate(-90deg);
                    }
                }
            }
            .question-container-2 {
                border-radius: 15px;
                .question-body {
                    box-shadow: 3px 6px 5px rgba($color: #000000, $alpha: 0.25);
                    border-radius: 15px;
                    margin: 10px auto 15px;
                    background: #fff;
                    height: auto;
                    width: 100%;
                    padding: 15px 5%;
                    font-weight: 600;
                    text-align: left;
                }
            }
        }
    }
}
</style>
