var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('div',{staticClass:"customer-box"},[_c('h3',[_vm._v(_vm._s(_vm.$t('customers')))]),_c('div',{staticClass:"customer-carousel"},[_c('Carousel',{attrs:{"perPageCustom":[
                    [320, 1],
                    [575, 2],
                    [991, 3],
                    [1200, 3],
                    [1600, 3],
                    [2200, 3],
                    [3200, 3] ],"navigationEnabled":false,"navigationNextLabel":'>',"navigationPrevLabel":'<'}},_vm._l((_vm.Customer),function(customer){return _c('Slide',{key:customer.customer_id,staticClass:"item-carousel"},[_c('div',{staticClass:"customer-item"},[_c('div',{staticClass:"customer-img"},[_c('img',{attrs:{"src":customer.customer_image,"alt":""}})]),_c('h4',[_vm._v(_vm._s(customer.customer_name))]),_c('h5',[_vm._v(_vm._s(customer.customer_job))]),_c('p',[_vm._v(" "+_vm._s(customer.customer_feelings)+" ")])])])}),1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }